.Header {
}

.carousel-root {
    margin: 0;
}

.header-control-bar {
    position: fixed;
    display: flex;
    width: 100%;
    flex-direction: row;
    z-index: 9;
}

.header-control-bar-btn {
    flex-grow: 1;
}
