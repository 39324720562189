.control-bar {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

.map-close-btn {
    position: absolute !important;
    right: 0;
    top: 5px;
}

.control-bar-btn-xs {
    flex-grow: 1;
}

.rotation-btn {
    animation: rotate 10s infinite;
    position: absolute !important;
    bottom: 5px;
    right: 5px;
}

footer {
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

@keyframes rotate {
    22% {
        transform: rotate(90deg);
    }
    44% {
        transform: rotate(0deg);
    }
}
