.App {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

html,
body {
    overflow: auto;
    height: auto;
    scroll-behavior: smooth;
}

.spinner-box {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.spinner-box .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

