.CarouselItem-cards {
    display: flex;
    flex-direction: row;
}

.products-tile-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
